import { TextInput } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { COUNTRIES, CountryAssetModel } from 'assets/geography';
import { SearchIcon } from 'common/assets';
import { CustomValue } from 'common/repositories/customization';
import colors from 'common/styles/colors';
import { ilike } from 'common/utils/string';
import Text from 'components/elements/text/base';
import VirtualizedComponent from 'components/virtualized-component';
import useCombinedRefs from 'hooks/use-combined-refs';
import { LoadOnIntersect } from 'hooks/use-kurosim-infinite-scroll';
import usePaginateData from 'hooks/use-paginate-data';
import { CountryListItem } from 'modules/main/components/area-card';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import BottomSheet, { BottomSheetRemote } from '../bottom-sheet';

interface CountrySelectBottomSheetProps {
  onChange?(country: CountryAssetModel): void;
  trailingType?: keyof CountryAssetModel;
}

const CountrySelectBottomSheet = React.forwardRef<
  BottomSheetRemote | null,
  CountrySelectBottomSheetProps
>(function (props, ref) {
  const { t } = useTranslation();
  const [search, setSearch] = useDebouncedState('', 800);

  const allCountries = Object.values(COUNTRIES).filter(
    (country) =>
      ilike(country.label, search) || country.dialCode.includes(search),
  );

  const { hasNextPage, loadNextPage, items, resetPage } =
    usePaginateData(allCountries);

  const innerRef = React.useRef<BottomSheetRemote | null>(null);
  const combinedRef = useCombinedRefs(innerRef, ref);

  React.useEffect(() => {
    resetPage();
  }, [resetPage, search]);

  return (
    <BottomSheet
      ref={combinedRef as any}
      title={t('common:country_other')}
      customization={{
        body: {
          style: CustomValue.combine({ padding: 0 }),
        },
        header: {
          extendedComponent: (
            <TextInput
              mt={16}
              leftSection={
                <SearchIcon size={20} color={colors.foregroundPrimary} />
              }
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t('common:search_extra', {
                extra: t('common:country_one'),
              })}
            />
          ),
        },
      }}
    >
      <ul>
        {items.map((item) => {
          return (
            <VirtualizedComponent
              type="bottom-sheet"
              maxWidth={800}
              maxHeight={51}
              key={item.id}
              threshold={0}
            >
              <CountryListItem
                key={item.id}
                country={{
                  id: item.id,
                  areaCode: item.id,
                  name: item.label,
                  slug: '',
                }}
                onClick={() => {
                  props.onChange?.(item);
                  innerRef.current?.close();
                }}
                customization={{
                  trailing: props.trailingType && (
                    <Text>{item[props.trailingType]}</Text>
                  ),
                }}
              />
            </VirtualizedComponent>
          );
        })}
        <LoadOnIntersect
          hasNextPage={hasNextPage}
          loadNextPage={loadNextPage}
          loading={false}
        />
      </ul>
    </BottomSheet>
  );
});

export default CountrySelectBottomSheet;
